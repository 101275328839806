<template>
  <div v-if="!loading">
    <v-container v-if="posts.length > 0">
      <v-row justify="center">
        <v-col cols="12" md="12" jus>
          <div v-for="(post, i) in posts" :key="i">
            <v-divider v-if="i > 0"></v-divider>
            <v-card class="mb-7">
              <v-card-title>
                <v-avatar class="profileImage" tile>
                  <v-img
                    style="cursor: pointer"
                    v-if="post.CompanyPost.Logo != null"
                    :src="post.CompanyPost.Logo"
                  ></v-img>
                  <v-img
                    style="cursor: pointer"
                    src="../../../assets/profile.png"
                    v-else
                  ></v-img>
                </v-avatar>
                <h2 class="h2">{{ post.CompanyPost.Name }}</h2>
              </v-card-title>
              <v-card-subtitle class="feedSubtitle mt-4">
                {{ get_date_string(post.Date) }}
              </v-card-subtitle>
              <v-card-text class="mt-4">
                <span v-html="get_post_content(post)" class="feedText"></span>
                <v-card-actions class="ma-1">
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="post.Type != 0"
                    @click="feed_details(post)"
                    color="secondary"
                    dark
                  >
                    <v-icon left v-if="post.Type == 1">mdi-file</v-icon>
                    <v-icon left v-if="post.Type == 2">mdi-calendar</v-icon>
                    {{ $t("feed_check") }}
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <h4 v-else style="width: 30%" class="h4 mx-auto">{{ $t("feed_empty") }}</h4>
  </div>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<style lang="scss" scoped>
.profileCard {
  padding: 15px;
  padding-top: 50px;
}
.profileImage {
  height: 100px !important;
  min-width: 100px !important;
  width: 48px !important;
  border-radius: 50% !important;
  position: static;
  z-index: 20;
  // left: 27%;
  // top: -6px;
}
.feedSubtitle {
  font-size: 20px;
}
.feedText {
  font-size: 20px;
}
@media screen and (max-width: 850px) {
  .profileImage {
    left: 33% !important;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "FeedPage",
  data: () => ({
    value: "",
    apiService: new ApiService(),
    posts: [],
    loading: false,
  }),
  async created() {
    this.loading = true;
    this.apiService
      .getRequest("company/feed")
      .then((resp) => {
        this.posts = JSON.parse(resp);
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  methods: {
    feed_details: function (post) {
      var index = 0;
      if (post.Type == 1) {
        index = 2;
      }
      this.$router.push({
        path: `/companies/${post.CompanyPost.CompanyId}`,
        params: { company_id: post.CompanyPost.CompanyId },
        query: { tab: index + 1 },
      });
    },
    get_post_content(post) {
      if (post.Type == 0) {
        return this.get_update_content(post.Object);
      } else if (post.Type == 1) {
        return this.get_file_content(post.Object);
      } else if (post.Type == 2) {
        return this.get_event_content(post.Object);
      } else {
        return this.$t("an_error_ocurred");
      }
    },
    get_update_content(post) {
      return `<h3 class="h3">${this.$t("feed_update")}</h3><br>${post.Post}`;
    },
    get_file_content(file) {
      return `<h3 class="h3">${this.$t("feed_file")}</h3><br><b>${this.$t(
        "name"
      )}:</b> ${file.FileName}`;
    },
    get_event_content(event) {
      return `<h3 class="h3">${this.$t("feed_event")}</h3><br><b>${this.$t(
        "name"
      )}:</b> ${event.Name} - ${this.get_date_string(
        event.Date
      )}<br><br><b>${this.$t("details")}:</b> ${event.Details}`;
      // return "<b>Testes</b>"
    },
    get_date_string(date) {
      var date = date.split("T");
      var time = date[1];
      if (time == "00:00:00") {
        time = "";
      } else {
        time = time.substring(0, 5);
      }
      date = date[0].split("-");
      return `${date[2]}/${date[1]}/${date[0]} ${time}`;
    },
  },
};
</script>
